.emptySpace {
  flex-grow: 50;
}


.MuiPaper-elevation8{
  width: 300px;
}

body {
  background: #111217;
}

.background {


  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.4)), url('../assets/login-bg.png');

  /* Background image is centered vertically and horizontally at all times */
  background-position: center bottom;

  /* Background image doesn't tile */
  background-repeat: no-repeat;

  /* Background image is fixed in the viewport so that it doesn't move when 
     the content's height is greater than the image's height */

  /* This is what makes the background image rescale based
     on the container's size */
  background-size: cover;

  /* Set a background color that will be displayed
     while the background image is loading */
  background-color: #464646;
  padding-bottom: 90px;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.MuiOutlinedInput-root {
  background: #1A1B1F;
}

.MuiOutlinedInput-notchedOutline {
  border-color: #1A1B1F !important;
}

.MuiChip-label {
  padding-left: 30px !important;
  padding-right: 30px !important;
  padding-bottom: 15px !important;
  padding-top: 15px !important;
  text-overflow: clip !important;
}

.MuiSlider-track {
  color: #BF9B30;
}

.MuiSlider-rail {
  color: #393b44;
}

.MuiSlider-thumb {
  color: #BF9B30;
  width: 22px !important;
  height: 22px !important;
  margin-top: -11px !important;
}

.MuiTableCell-root {
  background-color: #1D1E23;
  border-bottom: 0px !important;
}

.MuiTableCell-head {
  background-color: transparent;
}

.leftRoundedCell {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.rightRoundedCell {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.MuiTableCell-head {
  color: #525862 !important;
}

.mergePopover {
  backdrop-filter: blur(4px);
}

.mergeSortableHelper {
  z-index: 10000;
}

.logo {
  height: 50px;
  margin-right: 10px;
}

.raffle {
  height: 10px;
}

.strikesWarning {
  opacity: 0.3;
}

#social {
  text-decoration: none;
  color: blue;
}

#question {
  font-size: 20px;
}

#response {
  color: #A2ACB2;
}

.MuiSlider-valueLabel {
  left: revert !important
}